import React from "react";

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(4, -2) scale(0.4,0.4)">
        <polygon fill="none" points="127.4,100.3 125.2,108.5 129.7,108.5 " />
        <path
          fill="currentColor"
          d="M72.2,111.3c0-1.5-1.2-2.7-2.7-2.7h-35c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7h35
	C70.9,114,72.2,112.8,72.2,111.3z"
        />
        <polygon
          fill="currentColor"
          points="129.7,108.5 125.2,108.5 123.7,114 131.1,114 "
        />
        <path
          fill="currentColor"
          d="M96.8,108.5H90c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7h6.9c0.5,0,1.1-0.2,1.5-0.4l1-3.4
	C98.9,109.2,98,108.5,96.8,108.5z"
        />
        <path
          fill="currentColor"
          d="M95.5,92.8c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7h7.5l1.7-5.5H95.5z"
        />
        <path
          fill="currentColor"
          d="M72,98.3h2.3c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7H72c-1.5,0-2.7,1.2-2.7,2.7
	C69.3,97,70.5,98.3,72,98.3z"
        />
        <path
          fill="currentColor"
          d="M60.5,136.7L60.5,136.7c0,1.5,1.2,2.7,2.7,2.7h21.9c1.5,0,2.7-1.2,2.7-2.7v0c0-1.5-1.2-2.7-2.7-2.7H63.2
	C61.7,133.9,60.5,135.1,60.5,136.7z"
        />
        <path
          fill="currentColor"
          d="M118.1,136.7L118.1,136.7c0-0.4-0.1-0.8-0.3-1.2l-0.9,3.4C117.6,138.4,118.1,137.6,118.1,136.7z"
        />
        <path
          fill="currentColor"
          d="M74.2,151.7L74.2,151.7c0-1.5-1.2-2.7-2.7-2.7H53c-1.5,0-2.7,1.2-2.7,2.7v0c0,1.5,1.2,2.7,2.7,2.7h18.5
	C73,154.5,74.2,153.3,74.2,151.7z"
        />
        <path
          fill="currentColor"
          d="M85.2,151.7L85.2,151.7c0,0.8,0.3,1.5,0.9,2l1.4-4.7C86.2,149.2,85.2,150.4,85.2,151.7z"
        />
        <path
          fill="currentColor"
          d="M68,164.8c-1.5,0-2.7,1.2-2.7,2.7v0c0,1.5,1.2,2.7,2.7,2.7h13l1.7-5.5H68z"
        />
        <path
          fill="currentColor"
          d="M160.3,196.1c-7.7,0-10.3-3.7-11.2-6.8l-5.3-19.7h-32.5l-5.3,19.7c-0.9,3.1-3.5,6.8-11.2,6.8
	c-4.4,0-8.8-1.2-12.9-3.6c-4.5-2.6-6.8-6-6.8-9.9c0-0.6,0.1-1.3,0.4-2.5c0,0,0-0.1,0-0.1l32-104.9c1.1-3.6,3.7-6.3,7.6-8.2
	c3.6-1.7,7.7-2.6,12.3-2.6c4.6,0,8.7,0.9,12.3,2.6c3.9,1.8,6.5,4.6,7.6,8.1l32.2,105c0,0,0,0.1,0,0.1c0.3,1.2,0.4,1.9,0.4,2.5
	c0,3.9-2.3,7.3-6.8,9.9C169,194.8,164.7,196.1,160.3,196.1z M109.4,164.6h36.3c1.1,0,2.1,0.8,2.4,1.8l5.8,21.5
	c0.2,0.9,0.9,3.1,6.4,3.1c3.5,0,7-1,10.3-2.9c4.4-2.5,4.4-4.8,4.4-5.6c0,0,0-0.3-0.3-1.2L142.6,76.5c-0.7-2.2-2.3-3.8-5-5.1
	c-2.9-1.4-6.3-2.1-10.2-2.1s-7.3,0.7-10.2,2.1c-2.6,1.3-4.3,2.9-5,5.1l-32,104.8c-0.2,0.9-0.3,1.2-0.3,1.3c0,0.7,0,3,4.4,5.6
	c3.3,1.9,6.8,2.9,10.3,2.9c5.5,0,6.1-2.3,6.4-3.2l5.8-21.5C107.2,165.3,108.2,164.6,109.4,164.6z M140,148.9h-25.1
	c-0.8,0-1.5-0.4-2-1c-0.5-0.6-0.6-1.4-0.4-2.2L125,99.7c0.3-1.1,1.3-1.8,2.4-1.8s2.1,0.8,2.4,1.8l12.6,46.1c0.2,0.8,0,1.6-0.4,2.2
	C141.5,148.6,140.8,148.9,140,148.9z M118.1,143.9h18.6l-9.3-34.1L118.1,143.9z"
        />
      </g>
      <g>
        <path
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
        />
      </g>
    </g>
  </svg>
);

export default IconLoader;
